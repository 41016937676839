import revive_payload_client_stTbQL7lsI from "/app/node_modules/.pnpm/nuxt@3.12.2_@parcel+watcher@2.4.1_@types+node@22.13.0_encoding@0.1.13_eslint@8.57.0_ioredis@5_622kl4tduenomqgoz3jc3g6azy/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_OQvwVPpHBy from "/app/node_modules/.pnpm/nuxt@3.12.2_@parcel+watcher@2.4.1_@types+node@22.13.0_encoding@0.1.13_eslint@8.57.0_ioredis@5_622kl4tduenomqgoz3jc3g6azy/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_E706mKp0M8 from "/app/node_modules/.pnpm/nuxt@3.12.2_@parcel+watcher@2.4.1_@types+node@22.13.0_encoding@0.1.13_eslint@8.57.0_ioredis@5_622kl4tduenomqgoz3jc3g6azy/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_O0za1IszUf from "/app/node_modules/.pnpm/nuxt-site-config@2.2.12_@nuxt+devtools@1.3.7_rollup@4.17.2_vite@5.3.2_@types+node@22.13.0_ter_engdwwawawacuc3tvoma7byl3m/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.mjs";
import payload_client_gRm9KoXNai from "/app/node_modules/.pnpm/nuxt@3.12.2_@parcel+watcher@2.4.1_@types+node@22.13.0_encoding@0.1.13_eslint@8.57.0_ioredis@5_622kl4tduenomqgoz3jc3g6azy/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_VO3t4Tb06q from "/app/node_modules/.pnpm/nuxt@3.12.2_@parcel+watcher@2.4.1_@types+node@22.13.0_encoding@0.1.13_eslint@8.57.0_ioredis@5_622kl4tduenomqgoz3jc3g6azy/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_UHBxU3EKN8 from "/app/node_modules/.pnpm/nuxt@3.12.2_@parcel+watcher@2.4.1_@types+node@22.13.0_encoding@0.1.13_eslint@8.57.0_ioredis@5_622kl4tduenomqgoz3jc3g6azy/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import chunk_reload_client_wLkLm2KdvF from "/app/node_modules/.pnpm/nuxt@3.12.2_@parcel+watcher@2.4.1_@types+node@22.13.0_encoding@0.1.13_eslint@8.57.0_ioredis@5_622kl4tduenomqgoz3jc3g6azy/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import error_handler_client_sQhJOBiqXf from "/app/plugins/error-handler.client.ts";
import sentry_client_shVUlIjFLk from "/app/plugins/sentry.client.ts";
export default [
  revive_payload_client_stTbQL7lsI,
  unhead_OQvwVPpHBy,
  router_E706mKp0M8,
  _0_siteConfig_O0za1IszUf,
  payload_client_gRm9KoXNai,
  check_outdated_build_client_VO3t4Tb06q,
  components_plugin_KR1HBZs4kY,
  prefetch_client_UHBxU3EKN8,
  chunk_reload_client_wLkLm2KdvF,
  error_handler_client_sQhJOBiqXf,
  sentry_client_shVUlIjFLk
]